import React from 'react';
import Button from '../components/Button/Button';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function ThankYou() {
  return (
    <Layout lightHeader>
      <SEO title="Thank You | Aeldra" />
      <div className="thank-you-main black-bg">
        <div className="container">
          <h1>
            Thank You.
            <br />
            <span className="gold">Our team will be in touch shortly.</span>
          </h1>

          <Button whiteButton href="/">
            Go to Homepage
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default ThankYou;
